import { CursorArrowRaysIcon } from "@heroicons/vue/20/solid";

export const propertyDiagramCenterConsole = {
  order: 5,
  name: "Understand the property diagram control panel",
  subcategory: "Properties",
  icon: CursorArrowRaysIcon,
  iconColor: "text-rose-400",
  markdown: `# Understand the property diagram control panel

  The control panel is the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" %}bottom section{% /inlineRouterLink %} of the Tower Hunt {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}. It helps you explore the surrounding market, access relevant data, and make further contributions to the selected property. The control panel has three horizontal sections: {% inlineRouterLink articleId="the-role-of-the-map" sectionName="Mini Map" %}Mini Map{% /inlineRouterLink %}, center console, and legend.

  ![Control panel screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Center console

  {% callout type="tip" %}
  **Tip:** Learn more about the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" %}visual features{% /inlineRouterLink %} and {% inlineRouterLink articleId="property-diagram-interactions" %}interactions{% /inlineRouterLink %} of the diagram to maximize your productivity.
  {% /callout %}

  ## Legend`,
};
